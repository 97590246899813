<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="pre-line mb-2">
          {{item.name}}
          <span v-if="item.isDefault" class="text-success">
            --- ค่าเริ่มต้น
          </span>
        </div>
        <div class="text-success">
          <small>บัญชี: {{item.account.code}} ({{item.account.name}})</small>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-textarea
            class="col-12"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-textarea>

          <DocConfigFormAccount
            :docType="docType"
            :templateType="templateType"
            label="บัญชี"
            class="col-12"
            select="id"
            v-model="formData.accountId"
            :validations="[
              {text: 'required!', value: $v.formData.accountId.$dirty && !$v.formData.accountId.required}
            ]">
          </DocConfigFormAccount>
        </div>

        <div class="form-group">
          <sgv-input-check
            class="form"
            label="ค่าเริ่มต้น"
            inline
            v-model="formData.isDefault">
          </sgv-input-check>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormAccount from '@/views/doc_core/components/DocConfigFormAccount'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        name: '',
        accountId: null,
        isDefault: false
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      accountId: {required},
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.$v.formData.$reset()
          this.toggle = false
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.name = this.item.name
          this.formData.accountId = this.item.accountId
          this.formData.isDefault = this.item.isDefault
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.formData.name = ''
        this.formData.accountId = null
        this.formData.isDefault = false
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DocConfigFormAccount
  }
}
</script>

<style lang="css" scoped>
</style>
