import gql from 'graphql-tag'


const orderResponse = `
  id type name
  qty price discount discountPrice totalPrice
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name supplierSku
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const DETAIL_ORDER_RECENT = (templateType) => gql`query DETAIL_ORDER_RECENT ($docType: String!, $docId: Int!, $inventoryId: Int!) {
  orderRecent: detailDoc${templateType}OrderRecent (docType: $docType, docId: $docId, inventoryId: $inventoryId) {
    id type name
    qty price discount
  }
}`

export const LIST_OTHER_CONFIG = (templateType) => gql`query LIST_OTHER_CONFIG ($docType: String!, $contactId: Int!) {
  others: listDoc${templateType}OrderOtherConfig (docType: $docType, contactId: $contactId) {
    id type name isDefault vatPrice
  }
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}Order (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`
